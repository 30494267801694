// Add element-ui locale
import elementLocaleDE from 'element-ui/lib/locale/lang/de';
import elementLocaleEN from 'element-ui/lib/locale/lang/en';

import i18n from '../plugins/vue-i18n';

/* eslint no-underscore-dangle: ["error", { "allow": ["_vm"] }] */
export default {
  /**
   * TEST TIMEOUT
   */
  async testTimeout({ commit }) {
    const response = await this._vm.$http({
      method: 'get',
      url: `${localStorage.getItem('unimarkt_outdoor_api_url')}/testTimeouttt`,
      // url: `${localStorage.getItem('unimarkt_outdoor_api_url')}/op/user`,
      // params: {
      //   timeoutSeconds: 128,
      // },
      headers: {
        'X-Api-Key': `${localStorage.getItem('unimarkt_outdoor_api_key')}`,
      },
      timeout: 2000,
    });
    try {
      commit('SET_TEST', response.data);
      return response.data;
    } catch (e) {
      if (e.code === 'ECONNABORTED') {
        return 'timeout';
      }
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * ERROR LOG
   */
  async setError({ commit }, payload) {
    const formData = new FormData();
    formData.append('appType', payload.appType);
    formData.append('deviceType', payload.deviceType);
    formData.append('data', JSON.stringify(payload.jsonData));

    const response = await this._vm.$http.post(`${localStorage.getItem('unimarkt_outdoor_api_url')}/log/error`, formData, {
      headers: {
        'X-Api-Key': `${localStorage.getItem('unimarkt_outdoor_api_key')}`,
      },
    });
    try {
      commit('SET_ERROR', response.data);
      return response.data;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * SETTINGS
   */
  async setSettings({ commit }) {
    try {
      const response = await this._vm.$http.get(`${localStorage.getItem('unimarkt_outdoor_api_url')}/settings`, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('unimarkt_outdoor_api_key')}`,
        },
      });
      commit('SET_SETTINGS', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * AUTH
   */
  setAuthOutdoor({ commit }) {
    commit('SET_AUTH_OUTDOOR');
  },

  /**
   * USER
   */
  setUserOutdoor({ commit }) {
    commit('SET_USER_OUTDOOR');
  },

  /**
   * BOX TOKEN
   */
  async setBoxOutdoor({ commit }, { payload }) {
    const response = await this._vm.$http.get(`${localStorage.getItem('unimarkt_outdoor_api_url')}/boxByToken`, {
      params: {
        token: payload.token,
      },
      headers: {
        'X-Api-Key': `${localStorage.getItem('unimarkt_outdoor_api_key')}`,
      },
    });
    try {
      commit('SET_BOX_OUTDOOR', response.data);
      return true;
    } catch (e) {
      console.log(e.status);
      return false;
    }
  },

  /**
   * FAKE HOURS
   */
  async setFakeHours({ commit }, { payload }) {
    const response = await this._vm.$http.get(`${localStorage.getItem('unimarkt_outdoor_api_url')}/box/openingHoursFake`, {
      params: {
        boxID: payload.boxID,
      },
      headers: {
        'X-Api-Key': `${localStorage.getItem('unimarkt_outdoor_api_key')}`,
      },
    });
    try {
      commit('SET_FAKE_HOURS', response.data);
      return true;
    } catch (e) {
      console.log(e.status);
      return false;
    }
  },

  /**
   * GLOBAL LOADING
   */
  setGlobalLoadingOutdoor({ commit }, status) {
    commit('SET_GLOBAL_LOADING_OUTDOOR', status);
  },

  /**
  * TRANSLATIONS
  */
  async setI18nDataOutdoor({ commit }, payload) {
    // this._vm.$http.defaults.timeout = 18000;
    // this._vm.$http.defaults.timeoutErrorMessage = 'timeout';

    try {
      const response = await this._vm.$http({
        method: 'get',
        url: `${localStorage.getItem('unimarkt_outdoor_api_url')}/resources`,
        params: {
          locale: payload,
        },
        headers: {
          'X-Api-Key': `${localStorage.getItem('unimarkt_outdoor_api_key')}`,
        },
      });

      // update language in localStorage + update store language
      const responseData = response.data;
      const translationsDE = {};
      const translationsEN = {};
      let translationsFinal = {};
      responseData.forEach((row) => {
        // if german
        if (row.localeID === 22) {
          translationsDE[row.code] = row.text;
        }
        // if english
        if (row.localeID === 352) {
          translationsEN[row.code] = row.text;
        }
      });
      // if german
      if (payload === 'de_AT') {
        translationsFinal = {
          ...translationsDE,
          ...elementLocaleDE,
        };
      }
      // if english
      if (payload === 'en_US') {
        translationsFinal = {
          ...translationsEN,
          ...elementLocaleEN,
        };
      }
      localStorage.removeItem(payload);
      localStorage.removeItem('lang');
      localStorage.setItem(payload, JSON.stringify(translationsFinal));
      localStorage.setItem('lang', payload);
      i18n.setLocaleMessage(localStorage.getItem('lang'), translationsFinal);
      commit('SET_I18N_OUTDOOR', response.data);
      return i18n;
    } catch (e) {
      return e.status;
    }
  },
};
